<template>
  <v-dialog v-model="dialog.show" max-width="600">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title class="headline">
        New Presentation
      </v-card-title>
      <!-- Presentation -->
      <div class="dialog-presentation">
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- Title -->
          <v-text-field v-model="presentation.title" label="Presentation Name" outlined :rules="rules.presentationName"/>
          <!-- Slide Type -->
          <v-select v-model="presentation.visibility" :items="slideVisibilities" label="Slide Visibility" outlined required :rules="rules.slideVisibility"/>
        </v-form>
      </div>
      <!-- Dialog Actions -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="saveNewPresentation" :loading="progress">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogNewPresentation',
  data() {
    return {
      valid: false,
      progress: false,
      presentation: {
        title: '',
        visibility: '',
      },
      slideVisibilities: ['All', 'Member', 'Private'],
      rules: {
        presentationName: [
          v => !!v || 'Presentation name is required!'
        ],
        slideVisibility: [
          v => !!v || 'Visibility is required!'
        ],
      }
    }
  },
  computed: {
    dialog() {
      return this.$store.state.GlobalModules.dialogNewPresentation
    },
    user() {
      return this.$store.state.GlobalModules.user.user
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('closeDialogNewPresentation')
      this.$refs.form.reset()
    },
    saveNewPresentation() {      
      if (this.$refs.form.validate()) {
        this.progress = true
        this.presentation.userId = this.user.userId
        this.presentation.visibility = this.presentation.visibility.toLowerCase()
        this.$store.dispatch('createNewPresentation', {...this.presentation}).then(() => {
          this.closeDialog()
          this.progress = false
        })
      }
    }
  }
}
</script>

<style scoped src='./style/index.css'/>